@import "src/styles/mixins.scss";
@import "src/styles/shortcuts";

.BonusCard {
  background: $white;
  border-radius: 16px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  @include mobile {
    display: none;
  }
  @include beforeMobile {
    display: none;
  }
  box-shadow: 0 4px 16px rgba(37, 36, 36, 0.05);

  &:hover {
    box-shadow: 0 4px 24px rgba(37, 36, 36, 0.1);
  }

  &Title {
    position: relative;
    height: 126px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 16px 16px 0 0;

    & > span {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.01em;
      color: $white;
      position: absolute;
      top: 16px;
      left: 16px;
      width: 90%;
      @extend .OneLine;
    }

    & > img {
      width: 100%;
      height: auto;
      border-radius: 16px 16px 0 0;
    }
  }

  &Content {
    display: flex;
    flex-direction: column;
    padding: 16px;
    height: 61%;

    &Title {
      font-style: normal;
      font-weight: normal;
      font-size: 22px;
      line-height: 110%;
      color: $title;
      margin-bottom: 32px;
      @extend .ThreeLine;
    }

    &Info {
      display: flex;
      justify-content: space-between;
      margin-top: auto;
      min-height: 50px;

      &Left {
        display: flex;
        flex-direction: column;
        margin-top: auto;
        margin-bottom: 8px;
        min-width: 65%;

        &Status {
          display: flex;
          align-items: center;
          margin-bottom: 8px;

          & > img {
            margin-right: 8px;
          }

          & > span {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: -0.01em;
            color: #25B15B;
          }
        }

        &Date {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: -0.01em;
          color: $title;
        }
      }

      &Right {
        width: 100%;
        height: 48px;
        display: flex;
        align-items: center;

        &Image {
          width: 100%;
          height: 100%;
          background-position: right;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }
  }
}

.BonusCardOnMobile {
  @extend .BonusCard;
  min-height: 305px;

  .BonusCardContentTitle {
    min-height: 72px;
  }

  display: flex;
  flex-direction: column;
  @include mobile {
    display: flex;
  }
  @include beforeMobile {
    display: flex;
  }
}

.BonusCardMobile {
  position: relative;
  display: none;
  background: $white;
  box-shadow: 0 2px 4px rgba(43, 46, 74, 0.08);
  border-radius: 8px;
  width: 100%;
  padding: 16px;
  max-height: 97px;
  @include mobile {
    display: flex;
  }

  @include beforeMobile {
    display: flex;
  }

  &Status {
    position: absolute;
    right: 16px;
    top: 16px;
  }

  &Content {
    display: flex;
    width: 100%;

    &Logo {
      margin-right: 16px;
      width: 50%;
      display: flex;

      & > img {
        width: 100%;
        margin: auto;
        max-width: 72px;
        height: auto;
      }
    }

    &Text {
      display: flex;
      flex-direction: column;
      width: 100%;

      &Name {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 1.3;
        letter-spacing: -0.01em;
        color: $title;
        margin-bottom: 8px;
        @extend .OneLine;
        width: 90%;
      }

      &Title {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 1.3;
        color: $title;
        margin-bottom: 4px;
        @extend .OneLine;
      }

      &Date {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 1.3;
        letter-spacing: -0.01em;
        color: $lightGrayText;
        @extend .OneLine;
      }
    }
  }
}

.OneLine {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
}

.ThreeLine {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
}
