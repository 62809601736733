@import "src/styles/mixins";
@import "src/styles/shortcuts";

.Button {
  border-radius: 4px;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  outline: none;
  border: none;
  min-width: 128px;
  color: white;
  text-align: center;
  transition: .2s ease-in-out;
  min-height: 41px;

  &:hover {
    color: white;
  }

  & > img {
    width: 14px;
    height: 14px;
    animation-name: slowlyRotate;
    animation-duration: 1s;
  }
}

.Text {
  margin-left: 8px;

}

.MyButton {
  display: flex;
  align-items: center;

  &_Button {
    &_Red {
      @extend .Button;
      @include BTN($red, $redActive);
      &:hover {
        background-color: $Red_one
      }
    }

    &_Green {
      @extend .Button;
      @include BTN($btnGreen, $btnGreenActive);
      color: $white;
    }

  }

  &_Text {
    &_Success {
      @extend .Text;
    }

    &_Error {
      @extend .Text;
    }
  }
}

