@import "./src/styles/mixins";
@import "./src/styles/fonts";

.DonatorAdvices {
  margin-right: 16px;

  @media (min-width: 0px) and (max-width: 767px) {
    margin-right: 8px !important;
  }
}

.DonatorAdvicesModal {
  top: auto;
  bottom: auto;
  margin: 0 !important;
  height: auto;
  left: auto;
  right: auto;
  max-width: 400px;
  background: #FFFFFF !important;
  box-shadow: 0px 10px 25px rgba(37, 36, 36, 0.1) !important;
  border-radius: 20px !important;
  width: auto !important;
  //min-width: 1000px;
  min-width: calc(100% - 200px);

  @include tablet() {
    min-width: calc(100% - 200px);
  }

  @include laptop() {
    min-width: calc(100% - 400px);
  }

  @include desktop() {
    min-width: calc(100% - 500px);
  }

  @include beMobile() {
    top: auto;
    width: 100% !important;
    margin: 0 !important;
    height: 100%;
    bottom: 0;
    border-radius: 0 !important;
    max-width: 100% !important;
    min-width: initial !important;
  }

  &_Header {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 16px 24px;
    border-bottom: 1px solid $BaG_gray_six;

    &_Text {
      @extend .TTitle_lvl5;
    }

    &_Image {
      margin-left: auto;
      margin-top: auto;
      margin-bottom: auto;
      width: 24px;
      height: 24px;
    }
  }

  &_Content {
    padding: 48px !important;

    @include beMobile() {
      padding: 24px !important;
    }

    &_Image {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 24px;
      height: 24px;
      cursor: pointer;

      @include beMobile() {
        display: none;
      }
    }

    &_Data {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 32px;
      position: relative;
      text-align: center;

      &_Header {
        @extend .DH_lvl3;
        color: $BaG_gray_one;
        margin-bottom: 12px;
      }

      &_Info {
        @extend .Text_text_3;
        color: $BaG_gray_two;
        margin-bottom: 32px;
      }

      &_TextArea {
        @extend .Text_text_3;
        color: $BaG_black;
        padding: 16px;
        width: 100%;
        background: $White;
        border: 1px solid $BaG_gray_five;
        box-sizing: border-box;
        border-radius: 4px;
        resize: none;
        outline: none;
      }

      &_Counter {
        @extend .Caption_2;
        color: $BaG_gray_four;
        position: absolute;
        bottom: 8px;
        right: 8px;
      }
    }

    &_Button {
      width: 100%;
      display: flex;

      & > button {
        @extend .TTitle_button_large;
        border-radius: 4px;
        color: $White;
        padding: 18px 32px;
        outline: none;
        border: none;
        margin: auto;
        transition: .2s ease-in-out all;
      }

      &_Active {
        background-color: $Green_one;
        transition: .2s ease-in-out all;
        animation: button-fade-reverse .5s;

        &:hover {
          background-color: $Green_two;
        }

        &:active {
          background-color: $Green_two;
        }
      }

      &_Disable {
        animation: button-fade .5s;
        background-color: $BaG_gray_six;
        cursor: default !important;
      }

      &_Loading {
        @extend .TTitle_button_large;
        border-radius: 4px;
        color: $White;
        padding: 18px 32px;
        outline: none;
        border: none;
        margin: auto;
        transition: .2s ease-in-out all;
        background-color: $BaG_gray_six;
        animation: button-fade 1s;
        cursor: default !important;
      }
    }

    &_Done {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &_Image {
        width: 78px;
        height: auto;
        margin-bottom: 40px;
      }

      &_Text {
        text-align: center;

        &_Header {
          @extend .DH_lvl3;
          margin-bottom: 12px;
          color: $BaG_gray_one;
        }

        &_Footer {
          @extend .Text_text_2;
          color: $BaG_gray_two;
        }
      }
    }
  }
}

@keyframes button-fade {
  from {
    background-color: $Green_one;
  }
  to {
    background-color: $BaG_gray_six;
  }
}

@keyframes button-fade-reverse {
  from {
    background-color: $BaG_gray_six;
  }
  to {
    background-color: $Green_one;
  }
}
