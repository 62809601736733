@import "./src/styles/mixins";
@import "./src/styles/fonts";

.SocialBlock {
  display: grid;
  flex-direction: row;
  gap: 20px;
  grid-template-columns: 1fr 2fr;
  min-height: 352px;
  margin: 0 5px;

  @include UiMobile() {
    grid-template-columns: 1fr;
  }

  &_Left {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &_Right {
    position: relative;
  }
}

.FirstTimeHere {
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 40px;
  width: 100%;
  border-radius: 6px;
  height: 100%;
  transition: .2s ease-in-out all;
  box-shadow: 0 4px 16px rgba(37, 36, 36, 0.05);
  background-color: $White;

  &:hover {
    box-shadow: 0 4px 24px rgba(37, 36, 36, 0.1);
  }

  @include UiMobile() {
    padding: 24px;
  }

  &_Title {
    margin-bottom: 24px;
    z-index: 2;

    & > span {
      font-style: normal;
      font-weight: 500;
      font-size: 34px;
      line-height: 130%;
      color: #2B2E4A;
      position: relative;
    }
  }

  &_Lead {
    z-index: 2;
    width: 50%;
    @include UiMobile() {
      width: 100%;
    }

    & > span {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 150%;
      color: #5F6177;
      position: relative;
    }
  }

  &_Action {
    cursor: pointer;
    margin-top: auto;
    background: #2B2E4A;
    border-radius: 4px;
    padding: 16px 32px;
    z-index: 2;
    align-self: flex-start;
    @include UiMobile() {
      margin-top: 64px;
    }

    & > span {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: $White;
    }
  }

  &_Image {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 79%;
    @include UiLarge() {
      width: 61%;
    }
  }
}

.Social {
  box-shadow: 0 4px 16px rgba(37, 36, 36, 0.05);
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  width: 100%;
  height: 100%;
  transition: .2s ease-in-out all;
  cursor: pointer;

  &:hover {
    box-shadow: 0 4px 24px rgba(37, 36, 36, 0.1);
  }

  &[data-type='vk'] {
    background-color: #5092DF;
  }

  &[data-type='tg'] {
    background: linear-gradient(180deg, #2AABEE, #229ED9);
  }

  &[data-type='inst'] {
    background: linear-gradient(127deg, #5052D2, #E7A630, #DD2D42, #7B3CC1);
  }

  &_Title {
    width: 50%;
    margin-bottom: 16px;
    @include UiMobile() {
      width: 80%;
    }

    @include UiDesktop() {
      width: 80%;
    }

    @include UiLarge() {
      width: 80%;
    }

    & > span {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 130%;
      color: $White;
    }
  }

  &_Counter {
    background-color: #ffffff4d;
    border-radius: 6px;
    align-self: flex-start;
    padding: 6px 10px;

    & > span {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 130%;
      color: $White;
    }
  }

  &_Icon {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 32px;
    height: 32px;
  }

  &_Lines {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
  }
}