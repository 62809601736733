@import "src/styles/shortcuts";

.head_NoLogWrapper{
  display: flex;
  height: 100%;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
}

.head_NoLog {
  position: relative;
  margin: 24px 5px;
  padding: 48px 40px 36px;
  min-height: 334px;
  width: 100%;
  height: auto;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 16px rgba(37, 36, 36, 0.05);

  &:hover {
    box-shadow: 0 4px 24px rgba(37, 36, 36, 0.1);
  }
}

.head_NoLog_btn {
  padding: 16px 32px;
  margin: auto;
  display: block;
  text-align: center;
  width: 200px;
  color: white;
  background: #F73232;
  border-radius: 4px;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  border: none;
  outline: none;
  &:hover,
  &:focus{
    color: white;
    background: #CE0000;
  }
  &:disabled {
    color: white;
    background: #DEE0E3;
  }
}

.head_NoLog_title {
  margin-bottom: 36px;
  font-style: normal;
  font-weight: 900;
  font-size: 52px;
  line-height: 64px;
  color: #131416;

  @include UiLaptop(){
    font-size: 50px;
  }

  span {
    color: #F73232;
  }
}

.head_NoLog_subtitleWrap {
  position: absolute;
  top: 123px;
  right: 70px;

  p {
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $colorTitle;
  }
}


@media screen and (min-width: 1920px) {
  .head_NoLog {
    padding: 64px;
  }
  .head_NoLog_title{
    margin-bottom: 48px;
    font-size: 67px;
    line-height: 72px;
  }
  .head_NoLog_subtitleWrap {
    top: 154px;
    right: 177px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .head_NoLog {
    padding: 72px 40px 40px;
  }
  .head_NoLog_subtitleWrap{
    position: static;
    margin: 0 auto 56px;
    text-align: center;
    width: 406px;
    br{
      display: none;
    }
  }
  .head_NoLog_title{
    margin: 0 auto 8px;
    width: 623px;
    font-weight: 800;
    font-size: 40px;
    line-height: 44px;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .head_NoLog {
    margin: 25px 0 0;
    padding: 24px 24px 40px;
    min-height: 327px;
  }
  .head_NoLog_title{
    margin: 0 0 12px;
    text-align: left;
    width: auto;
    font-weight: 800;
    font-size: 32px;
    line-height: 40px;
  }
  .head_NoLog_subtitleWrap{
    position: static;
    margin: 0 0 24px;
    width: auto;
  }
  .head_NoLog_btn{
    margin: 0 auto 0 0;
  }
}
@media (max-width: 367px) {
  .head_NoLog {
    margin: 0;
    padding: 24px 24px 40px;
    min-height: 427px;
  }
}
