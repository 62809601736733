@import "src/styles/mixins.scss";
@import "src/styles/shortcuts";

.mainNewsCard {
  background-size: cover;
  border-radius: 6px;
  min-height: 420px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  box-shadow: 0 4px 16px rgba(37, 36, 36, 0.05);

  &:hover {
    box-shadow: 0 4px 24px rgba(37, 36, 36, 0.1);
  }

  &_info {
    width: 100%;
    height: 40%;
    min-height: 210px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
    padding: 32px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 6px 6px 0 0;
    z-index: 1;

    &_title {
      display: block;
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 34px;
      color: $white;
    }

    &_time {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: $white;
    }
  }

  &_img {
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    border-radius: 6px;
  }
}
