@import "src/styles/mixins";
@import "src/styles/shortcuts";

.mainBannerCard {
  min-height: 334px;
  height: auto;
  border-radius: 6px;
  background: linear-gradient(96.4deg, #F7CC61 0%, #F5B12D 100%);
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  margin: 24px 5px;
  transition: .2s ease-in-out;
  cursor: pointer;
  box-shadow: 0 4px 16px rgba(37, 36, 36, 0.05);

  &:hover {
    box-shadow: 0 4px 24px rgba(37, 36, 36, 0.1);
    transition: .2s ease-in-out;
  }

  &_date {
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 21px;
    color: $white;
    position: relative;
    z-index: 1;
  }

  &_title {
    width: 68%;
    font-style: normal;
    font-weight: 500;
    font-size: 64px;
    line-height: 84px;
    margin-bottom: 32px;
    color: $white;
    position: relative;
    z-index: 1;
  }

  &_titleKm {
    width: 68%;
    font-style: normal;
    font-weight: 500;
    font-size: 64px;
    line-height: 84px;
    margin-bottom: 32px;
    color: $white;
    position: relative;
    z-index: 1;
  }

  &_btn {
    width: 233px;
    height: 55px;
    background: $white;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #F6B432;
    position: relative;
    z-index: 1;

    &:hover {
      color: #F6B432;
      cursor: pointer;
    }
  }

  &_img {
    position: absolute;
    right: 0;
    height: 100%;
    top: 0;

    &_default {
      display: none;
    }

    &_def_desktop {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      @include beMobile() {
        display: none;
      }
    }
  }

  &Unpublished {
    position: absolute;
    background: $red;
    border-radius: 9.5px;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: $white;
    top: 16px;
    left: 40px;
    padding: 2px 4px;
  }
}

@media (min-width: 1920px) {
  .mainBannerCard {
    min-height: 412px;
  }
}

@media (max-width: 1920px) {
  .mainBannerCard {
    &_date {
      font-size: 24px;
      line-height: 21px;
    }
  
    &_title {
      width: 66%;
      font-size: 58px;
      line-height: 72px;
      margin-bottom: 32px;
    }

    &_titleKm {
      width: 58%;
      font-size: 52px;
      line-height: 62px;
      margin-bottom: 32px;
    }
  }
}

@media (max-width: 1439px) {
  .mainBannerCard {
    min-height: 428px;
    &_date {
      font-size: 24px;
      line-height: 21px;
    }
  
    &_title {
      width: 52%;
      font-size: 48px;
      line-height: 68px;
      margin-bottom: 32px;
    }

    &_titleKm {
      width: 55%;
      font-size: 52px;
      line-height: 62px;
      margin-bottom: 32px;
    }
  }
}

@media (max-width: 1199px) {
  .mainBannerCard {
    max-height: 306px;
    min-height: 306px;
    &_date {
      font-size: 14px;
      line-height: 21px;
    }
  
    &_title {
      width: 44%;
      font-size: 24px;
      line-height: 38px;
      margin-bottom: 32px;
    }

    &_titleKm {
      width: 60%;
      font-size: 28px;
      line-height: 38px;
      margin-bottom: 32px;
    }

    &_btn {
      width: 190px;
      height: 45px;
      background: $white;
      border-radius: 4px;
      font-weight: 500;
      font-size: 12px;
      line-height: 150%;
    }
  }
}

@media (max-width: 767px) {
  .mainBannerCard {
    min-height: 440px;
    margin: 0;
    height: auto;
    border-radius: 6px;
    background: linear-gradient(96.4deg, #F7CC61 0%, #F5B12D 100%);
    padding: 20px 32px 32px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &_date {
      font-size: 14px;
      line-height: 21px;
    }

    &_title {
      width: 100%;
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 34px;
      color: $white;
      position: relative;
      z-index: 1;
    }

    
    &_titleKm {
      width: 100%;
    }

    &_img {
      display: flex;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: auto;
      top: unset;

      &_default {
        display: flex;
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }

    &_btn {
      margin-top: auto;
      width: 249px;
      height: 55px;
      background: $white;
      border-radius: 4px;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #F6B432;
      position: relative;
      z-index: 1;

      &:hover {
        color: #F6B432;
        cursor: pointer;
      }
    }
  }
}

/*
@media (max-width: 768px) {
  .mainBannerCard {
    min-height: 427px;
  }
}*/
