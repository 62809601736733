@import "./src/styles/mixins";
@import "./src/styles/fonts";

.TopDonorFilterByContainer {
  width: 100%;
  overflow-x: scroll;
  display: flex;
  align-self: flex-start;
  justify-content: flex-start;
  margin-bottom: 32px;
  white-space: nowrap;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.TopDonorFilterBy {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding: 8px 16px;
  background: $White;
  border-radius: 12px;

  &_Type {
    @extend .Text_text_2;
    line-height: 32px;
    font-weight: 700;
    color: $BaG_gray_two;
    margin-right: 8px;
  }

  &_Text {
    @extend .Mobile_lead_3;
    line-height: 32px;
    color: $BaG_gray_two;
  }
}
